<template>
  <div>
    <div class="bottom">
      <div class="bottom-con">
        <div class="content">
          <div class="one">
            <div class="img"></div>
            <!-- <div class="shouji"><span></span> 征订热线：63488450 63484790</div> -->
            <div class="shouji"><span></span> 广告热线：63480010 63482888</div>
          </div>
          <div class="text">
            <div class="text-title">
              <div class="tip">WEBSITE</div>
              <div>网站导航</div>
            </div>
            <div class="text-list">
              <div>地址：吴江区鲈乡南路1887号</div>
            </div>
          </div>
          <div class="text2">
            <div class="text-title">
              <div class="tip">ORGANIZER</div>
              <div>承办单位</div>
            </div>
            <div class="text-list">
              <div>苏州市吴江区融媒体中心</div>
            </div>
            <div class="beian">
              <a
                style="color: #575857"
                href="https://beian.miit.gov.cn"
                target="_blank"
                >苏ICP备2024070878号-1</a
              >
            </div>
          </div>
          <div class="text3">
            <div class="text-title">
              <div class="tip">FOLLOW</div>
              <div>关注我们</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .bottom {
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    .bottom-con {
      height: 256px;
      padding-top: 10px;
      position: relative;
      width: 750px;
      margin: 0 auto;
      .content {
        width: 750px;
        margin: 70px auto 0px;
        height: 100%;
        font-size: 14px;
        display: flex;
        flex-wrap: nowrap;
        flex-shrink: 0;
        .one {
          width: 370px;
          text-align: left;
          margin-left: 25px;
          .img {
            height: 74px;
            background: no-repeat url(../assets/new_logo.png);
            background-size: contain;
          }
          .shouji {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #575857;
            margin-top: 24px;
            span {
              display: inline-block;
              width: 36px;
              height: 34px;
              background: no-repeat url(../assets/bottomicon.png);
              background-size: contain;
              vertical-align: middle;
              margin-right: 20px;
            }
          }
        }
        .text {
          height: auto;
          width: 195px;
          flex-shrink: 0;
          margin-left: 20px;
          color: #000;
          text-align: left;
          .text-title {
            height: 55px;
            text-align: left;
            .tip {
              font-weight: bold;
              font-size: 18px;
            }
            div {
              font-size: 14px;
              color: #575857;
              height: 3rem;
            }
          }
          .text-list {
            margin-top: 14px;
            font-size: 14px;
            color: #575857;
            a {
              display: inline-block;
              margin: 12px 17px 0 0;
              color: #575857;
            }
          }
        }
        .text2 {
          width: 322px;
          margin-left: 30px;
          text-align: left;
          .text-title {
            height: 45px;
            text-align: left;
            .tip {
              font-weight: bold;
              font-size: 1.8rem;
            }
            div {
              font-size: 14px;
              color: #575857;
              height: 3rem;
            }
          }
          .text-list {
            margin-top: 30px;
            font-size: 14px;
            color: #575857;
            a {
              display: inline-block;
              margin: 12px 17px 0 0;
              color: #575857;
            }
          }
          .beian {
            height: 3rem;
            margin-top: 1rem;
            a {
              color: #575857;
            }
          }
        }
        .text3 {
          width: 147px;
          margin-left: 20px;
          text-align: left;
          .text-title {
            height: 45px;
            text-align: left;
            .tip {
              font-weight: bold;
              font-size: 18px;
            }
            div {
              font-size: 14px;
              color: #575857;
            }
            div {
              height: 30px;
            }
          }
          .text-list {
            margin-top: 30px;
            font-size: 14px;
            color: #575857;
            height: 86px;
            background: no-repeat url(../assets/bgwx.jpg);
            background-size: contain;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .bottom {
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    .bottom-con {
      height: 309px;
      padding-top: 10px;
      position: relative;
      width: 1200px;
      margin: 0 auto;
      // border-top: 1px solid #ccc;
      .content {
        width: 1200px;
        margin: 70px auto 0px;
        height: 95.8px;
        font-size: 14px;
        display: flex;
        flex-wrap: nowrap;
        flex-shrink: 0;
        .one {
          width: 360px;
          text-align: left;
          margin-left: 95px;
          .img {
            height: 74px;
            background: no-repeat url(../assets/logo.png);
            background-size: contain;
          }
          .shouji {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #575857;
            margin-top: 12px;
            span {
              display: inline-block;
              width: 36px;
              height: 34px;
              background: no-repeat url(../assets/bottomicon.png);
              background-size: contain;
              vertical-align: middle;
              margin-right: 20px;
            }
          }
        }
        .text {
          height: 30px;
          width: 195px;
          flex-shrink: 0;
          margin-right: 32px;
          color: #000;
          text-align: left;
          .text-title {
            height: 63px;
            text-align: left;
            margin-top: 12px;
            .tip {
              font-weight: bold;
              font-size: 18px;
            }
            div {
              font-size: 14px;
              color: #575857;
              height: 3rem;
            }
          }
          .text-list {
            margin-top: 20px;
            font-size: 14px;
            color: #575857;
            a {
              display: inline-block;
              margin: 12px 17px 0 0;
              color: #575857;
            }
          }
        }
        .text2 {
          width: 256px;
          margin-left: 50px;
          text-align: left;
          .text-title {
            height: 63px;
            text-align: left;
            margin-top: 12px;
            .tip {
              font-weight: bold;
              font-size: 1.8rem;
            }
            div {
              font-size: 14px;
              color: #575857;
              height: 3rem;
            }
          }
          .text-list {
            margin-top: 20px;
            font-size: 14px;
            color: #575857;
            a {
              display: inline-block;
              margin: 12px 17px 0 0;
              color: #575857;
            }
          }
          .beian {
            height: 3rem;
            margin-top: 1rem;
            a {
              color: #575857;
            }
          }
        }
        .text3 {
          width: 147px;
          margin-left: 20px;
          text-align: left;
          .text-title {
            height: 45px;
            text-align: left;
            margin-top: 12px;
            .tip {
              font-weight: bold;
              font-size: 18px;
            }
            div {
              font-size: 14px;
              color: #575857;
            }
            div {
              height: 30px;
            }
          }
          .text-list {
            margin-top: 18px;
            font-size: 14px;
            color: #575857;
            height: 86px;
            background: no-repeat url(../assets/bgwx.jpg);
            background-size: contain;
          }
        }
      }
    }
  }
}
</style>
